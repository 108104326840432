import Link from 'next/link'
import React, { PropsWithChildren } from 'react'
import cn from 'classnames'
import { BANNER_TYPE, MAIN_BANNER_TYPE } from '@constants/common'
import { useBrandThemeContext } from '@contexts/brandThemeContext'
import Button from '@components/ui/Button'
import { ArrowRightCopperStudio } from '@components/iconSetCopperStudio/ArrowRightCopperStudio'
import sb from './Banner.module.css'
import { SmallBannerNineFit } from './SmallBannerNineFit'

export interface BannerProps {
  title: string
  subtitle: string
  buttonText?: string
  link?: string
  background?: string
  size?: 'big' | 'small' | 'xsmall'
  isAboutPage?: boolean
  isCentered?: boolean
  isHomeBrand?: boolean
}

const BannerButtonWrapper = ({
  link,
  isNA,
  children,
}: PropsWithChildren<{ link: string; isNA: boolean }>) => {
  if (isNA) {
    return <>{children}</>
  }

  return (
    <Link href={link} passHref>
      {children}
    </Link>
  )
}

const BannerWrapper = ({
  children,
  link,
  isBig,
  isHomeBrand,
  isNA,
  isNinelife,
  isSmall,
  isXSmall,
}: PropsWithChildren<{
  link?: string
  isBig: boolean
  isSmall: boolean
  isXSmall: boolean
  isNA: boolean
  isNinelife: boolean
  isHomeBrand: boolean
}>) => {
  if (link && isNA) {
    return (
      <Link data-testid='homepage_banner' href={link} passHref>
        <a
          className={cn({
            relative: true,
            'w-full': isBig,
            'md:w-1/2 w-full': isSmall,
            'xl:w-1/4 sm:w-1/2 w-full px-4': isXSmall,
            'px-4 pb-4': !isNA && isSmall,
            'py-0 px-4': isNA && isSmall,
            'sm:pb-8 px-4 pb-4': isNinelife && isXSmall,
            'md:w-1/3': isSmall && isHomeBrand,
          })}
        >
          {children}
        </a>
      </Link>
    )
  }

  return (
    <div
      data-testid='homepage_banner'
      className={cn({
        relative: true,
        'w-full': isBig,
        'md:w-1/2 w-full': isSmall,
        'xl:w-1/4 sm:w-1/2 w-full px-4': isXSmall,
        'px-4 pb-4': !isNA && isSmall,
        'py-0 px-4': isNA && isSmall,
        'sm:pb-8 px-4 pb-4': isNinelife && isXSmall,
        'md:min-w-[33.3%] md:max-w-[33.3%]': isSmall && isHomeBrand,
      })}
    >
      {children}
    </div>
  )
}

export const Banner: React.FC<BannerProps> = props => {
  const {
    background,
    title,
    subtitle,
    buttonText,
    link,
    size = 'big',
    isAboutPage,
    isCentered = true,
    isHomeBrand,
  } = props

  const isSmall = size === 'small'
  const isXSmall = size === 'xsmall'
  const isBig = size === 'big'
  const {
    home_page_small_banner_type: smallBannerType,
    home_page_main_banner_type: mainBannerType,
    banner_overlay_opacity: bannerOverlayOpacity,
  } = useBrandThemeContext()
  const isBannerType2 = smallBannerType === BANNER_TYPE.TYPE_2
  const isNA = smallBannerType === BANNER_TYPE.NINTH_AVENUE
  const isNinelife = smallBannerType === BANNER_TYPE.NINELIFE
  const isMainBannerTopLeft = mainBannerType === MAIN_BANNER_TYPE.TOP_LEFT
  const isMainBannerCenterLeft = mainBannerType === MAIN_BANNER_TYPE.CENTER_LEFT
  const isMainBannerCentered = mainBannerType === MAIN_BANNER_TYPE.CENTERED

  if (isSmall && smallBannerType === BANNER_TYPE.NINEFIT) {
    return <SmallBannerNineFit {...props} />
  }

  return (
    <BannerWrapper
      isBig={isBig}
      isHomeBrand={isHomeBrand || false}
      isNA={isNA}
      isNinelife={isNinelife}
      isSmall={isSmall}
      isXSmall={isXSmall}
      link={link}
    >
      <div
        style={{
          background: `url('${background}') center center no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: isCentered ? 'center center' : '0 0',
        }}
        className={cn({
          'rounded-banner': isSmall || isXSmall,
        })}
      >
        <div
          className={cn('flex flex-col relative', {
            ' sm:pt-40 pt-32 sm:pb-40 pb-32 text-center items-center': isBig,
            'justify-end items-center pl-5 pr-5 pb-5 sm:h-[27rem] h-64':
              isNinelife && isSmall,
            'sm:p-10 p-5 justify-center h-64': isSmall && !isNinelife,
            'h-64 justify-end items-center pl-5 pr-5 pb-5': isXSmall,

            'border-bannerBorder border-[10px]': !isBig && isNinelife,
            'lg:pt-64 lg:pb-48 sm:mt-0 mt-5 sm:h-[27rem]':
              !isNA && !isNinelife && isSmall,
            'lg:pt-24 lg:pb-88 md:pl-30 md:text-left md:items-start h-[379px] sm:h-[491px] mt-4 md:mt-9':
              isNA && isSmall,
            'lg:pt-64 lg:pb-48': isMainBannerCentered && isBig,
            'sm:h-[460px]': isXSmall,
            'md:text-left md:items-start':
              (isMainBannerTopLeft || isMainBannerCenterLeft) &&
              isBig &&
              !isAboutPage,
            'lg:pt-24 lg:pb-88 md:pl-30':
              isMainBannerTopLeft && isBig && !isAboutPage,
            'lg:pt-64 lg:pb-[281px] md:pl-30':
              isMainBannerCenterLeft && isBig && !isAboutPage,
          })}
          style={{ background: `rgba(0,0,0,${bannerOverlayOpacity}%)` }}
        >
          {/* <img
          style={{
            objectFit: 'cover',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
          alt='banner'
          src={String(background)}
        /> */}
          <div
            className={cn('flex-col z-10', {
              'max-w-xl': isSmall,
              [sb.smallBannerOverlay]: isSmall && isBannerType2,
              [sb.smallBannerOverlayNinthAvenue]: isSmall && isNA,
              'max-w-5xl px-4': isMainBannerCentered && isBig && !isHomeBrand,
              'px-4 max-w-full': isMainBannerCenterLeft && isBig && isHomeBrand,
              'max-w-[659px]':
                (isMainBannerTopLeft || isMainBannerCenterLeft) &&
                isBig &&
                !isAboutPage,
            })}
          >
            {isSmall && !isNinelife ? (
              <h2
                className={cn('text-white tracking-px block text-32', {
                  '!text-black': isBannerType2,
                  'font-bold capitalize mb-6': !isNA,
                  'lg:mb-0': isHomeBrand,
                  'uppercase tracking-px !text-black': isNA,
                })}
              >
                {title}
              </h2>
            ) : null}
            {isBig ? (
              <h1
                className={cn(
                  'text-white block lg:text-[70px] md:text-5xl text-32',
                  {
                    'capitalize leading-10 md:leading-none font-bold':
                      isMainBannerCentered,
                    'px-4 md:max-w-full':
                      isMainBannerCenterLeft && isBig && isHomeBrand,

                    'uppercase leading-[50px] md:leading-[75px] tracking-[5px] md:tracking-px':
                      (isMainBannerTopLeft || isMainBannerCenterLeft) &&
                      !isAboutPage,
                  },
                )}
              >
                {title}
              </h1>
            ) : null}
            {subtitle && (
              <h2
                className={cn('text-white block', {
                  'md:text-2xl text-base max-w-4xl font-semibold':
                    isBig && !isAboutPage,
                  'md:max-w-full': isHomeBrand && isBig,
                  'md:text-xl font-bold': isHomeBrand && !isAboutPage,
                  'my-4 lg:my-6': !isNA || isBig,
                  'my-4 lg:mb-4 lg:mt-0': isHomeBrand,
                  'md:text-2xl text-base': (!isNA && isSmall) || isAboutPage,
                  '!text-black mt-1 mb-2.5 md:my-2 text-sm md:leading-[50px]':
                    isNA && isSmall,
                })}
              >
                {subtitle}
              </h2>
            )}
            {(link && !isNinelife) || (isBig && link) ? (
              <BannerButtonWrapper isNA={isNA} link={link}>
                <Button
                  data-testid='banner_shop_now_button'
                  className={cn({
                    [sb.bannerButton]: isBig,
                    'md:m-auto': isSmall && isBannerType2 && !isNA,
                    'mx-auto': isBig,
                    'md:mr-auto md:ml-0 mt-4 md:mt-6':
                      (isMainBannerTopLeft || isMainBannerCenterLeft) &&
                      isBig &&
                      !isAboutPage,
                    'mt-5':
                      !subtitle && isSmall && smallBannerType !== 'type 1',
                  })}
                  variant={isNA && isSmall ? 'outlinedBrand' : 'brand'}
                  size={isNA && isSmall ? 'medium' : undefined}
                >
                  <div className='flex items-center gap-1'>
                    <span className='mr-2'>{buttonText}</span>

                    {isHomeBrand && !isBig ? (
                      <ArrowRightCopperStudio className='hidden md:block' />
                    ) : null}
                  </div>
                </Button>
              </BannerButtonWrapper>
            ) : null}
            {isNinelife && (isSmall || isXSmall) ? (
              <BannerButtonWrapper isNA={isNA} link={link || '/'}>
                <Button
                  data-testid='banner_shop_now_button'
                  className={cn({
                    [sb.bannerButton]: isBig,
                    'md:m-auto': isSmall && isBannerType2 && !isNA,
                    'mx-auto': isBig,
                    'md:mr-auto md:ml-0 mt-4 md:mt-6':
                      (isMainBannerTopLeft || isMainBannerCenterLeft) &&
                      isBig &&
                      !isAboutPage,
                    'mt-5':
                      !subtitle && isSmall && smallBannerType !== 'type 1',
                  })}
                  variant='inverted'
                  size={isNA && isSmall ? 'medium' : undefined}
                >
                  <span className='font-semibold text-black text-sm w-1/2'>
                    {title}
                  </span>
                  <div className='text-sm self-stretch border-r-2 border-bannerSecondaryColor ml-2 mr-2' />
                  <span className='font-semibold text-bannerSecondaryColor text-sm w-1/2'>
                    {buttonText}
                  </span>
                </Button>
              </BannerButtonWrapper>
            ) : null}
          </div>
        </div>
      </div>
    </BannerWrapper>
  )
}
